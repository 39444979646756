import React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import PlainHeader from "components/ui/base/PlainHeader";
import Customers from "components/ui/extended/Customers";
import Register from "components/ui/extended/RegisterForCustomerService";
import { getGlossaryPathBySlug } from "utils/path";
import { Term as TermType } from "app-types/term";
import { PageContainer } from "styles/global.style";
import { Row } from "styled-bootstrap-grid";
import { createRendererAst } from "utils/render-ast";
import { TermBodyContainer } from "styles/glossary.style";
import RelatedTerms from "components/ui/extended/RelatedTerms";
import { BASE_DOMAIN } from "constants/paths";

export interface TermProps {
  pageContext: {
    term: TermType;
    relatedTerms: TermType[];
  };
  location: Location;
}

const Term = ({ pageContext: { term, relatedTerms }, location }: TermProps) => {
  const renderAst = createRendererAst({});

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={term.term}
        description={term.metaDescription}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Article",
            headline: term.term,
            description: term.metaDescription,
            url: `https://www.${BASE_DOMAIN}${getGlossaryPathBySlug(
              term.slug
            )}`,
            inLanguage: "en",
            isAccessibleForFree: "True",
            datePublished: term.createdAt,
            dateModified: term.updatedAt,
            name: term.term,
            publisher: {
              "@type": "Organization",
              name: "Customerly",
              url: "https://www.customerly.io",
              logo: {
                "@type": "ImageObject",
                url: "https://content.customerly.io/branding/logo/filled-with-notification.png",
              },
            },
          },
        ]}
      />
      <PlainHeader
        title={term.term}
        description={term.metaDescription}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Glossary",
            path: "/glossary/",
          },
          {
            name: term.term,
            path: getGlossaryPathBySlug(term.slug),
          },
        ]}
      />
      <PageContainer>
        <Row>
          <TermBodyContainer>
            {renderAst(term.body.childMarkdownRemark.htmlAst)}
          </TermBodyContainer>
        </Row>

        {relatedTerms && relatedTerms.length > 0 && (
          <RelatedTerms relatedTerms={relatedTerms} />
        )}
      </PageContainer>

      <Register />

      <Customers />
    </GlobalWrapper>
  );
};

export default Term;
