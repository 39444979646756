import {
  TermContainer,
  TermDescription,
  TermTitle,
} from "styles/glossary.style";
import { Term } from "app-types/term";
import React, { FunctionComponent } from "react";
import { getGlossaryPathBySlug } from "utils/path";
import { Link } from "gatsby";
import {
  RelatedTermsContainer,
  StyledRelatedTerms,
} from "components/ui/extended/RelatedTerms/index.style";

interface RelatedTermsProps {
  relatedTerms: Term[];
}

const RelatedTerms: FunctionComponent<RelatedTermsProps> = ({
  relatedTerms,
}) => {
  return (
    <StyledRelatedTerms>
      <h3>Related Terms</h3>
      <RelatedTermsContainer>
        {relatedTerms.map((relatedTerm) => (
          <TermContainer key={relatedTerm.slug}>
            <TermTitle>
              <Link to={getGlossaryPathBySlug(relatedTerm.slug)}>
                {relatedTerm.term}
              </Link>
            </TermTitle>
            <TermDescription>{relatedTerm.metaDescription}</TermDescription>
          </TermContainer>
        ))}
      </RelatedTermsContainer>
    </StyledRelatedTerms>
  );
};

export default RelatedTerms;
