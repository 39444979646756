import styled from "styled-components";
import { TermContainer } from "styles/glossary.style";

const StyledRelatedTerms = styled.section`
  margin: 64px 0;

  h3 {
    font-size: 18px;
    margin-top: 16px;
    color: ${(props) => props.theme.titleColor};
  }
`;

const RelatedTermsContainer = styled.div`
  padding: 16px 0;
  display: flex;
  gap: 28px;
  justify-content: flex-start;
  flex-wrap: wrap;

  ${TermContainer} {
    flex-basis: calc(33.333% - 28px);
    max-width: calc(33.333% - 28px);
    margin-bottom: 0;
  }
`;

export { StyledRelatedTerms, RelatedTermsContainer };
